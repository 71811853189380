import React, {useState} from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import Img from "gatsby-image"
import {Formik} from "formik";
import gql from "graphql-tag";
import {Mutation} from "react-apollo";
import SEO from "../components/seo";
import {client} from '../apollo/client';

export default ({data: {datoCmsContactuspage: p}}) => {
  const [status, setStatus] = useState('')

  const INSERT_CONTACT_REQUEST = gql`
      mutation InsertContactRequest($input:InsertContactRequestInput!) {
          insertContactRequest(input: $input) {
              id
              email
          }
      }
  `

  return <Layout>
    <SEO title='Contact us' seo={p.seoMetaTags}/>
    <Img fluid={p.image.fluid}/>
    <div className="flex flex-col items-center mb-4 md:mb-16">
      <div className="font-title text-2xl tracking-wide uppercase mt-8  tracking-wide">Contact us</div>
      <div className="font-title text-xl tracking-wide text-purple-800 uppercase mt-2  tracking-wide">Connect with
        LookStyler
      </div>
      <div className="font-sans font-light mt-2 mx-2 md:mx-32 text-center"
           dangerouslySetInnerHTML={{__html: p.textconnect}}/>
      <div className="font-title text-xl uppercase mt-8 md:mt-8 text-center tracking-wide">Get in touch</div>
      <div className="font-sans font-light mt-2 mx-2 md:mx-32 text-center"
           dangerouslySetInnerHTML={{__html: p.textcontact}}/>
      <Mutation
        client={client}
        mutation={INSERT_CONTACT_REQUEST}>
        {(insertContactRequest) => (<Formik
            initialValues={{email: '', firstName: '', lastName: '', message: ''}}
            validate={values => {
              let errors = {};
              if (!values.email) {
                errors.email = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              if (!values.firstName) {
                errors.firstName = 'Required';
              }
              if (!values.lastName) {
                errors.lastName = 'Required';
              }
              if (!values.message) {
                errors.message = 'Required';
              }
              return errors;
            }}
            onSubmit={async ({recaptcha, ...input}, {setSubmitting, resetForm}) => {
              setStatus('')
              insertContactRequest({variables: {input}})
                .then(() => {
                  setSubmitting(false)
                  resetForm()
                  setStatus('ok')
                })
                .catch(e => {
                  console.error(e)
                  setSubmitting(false)
                  setStatus('error')
                })
            }}
          >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => {
              const visible = status === '' ? 'hidden' : ''
              const color = status === 'ok' ? 'bg-green-300 text-green-900 ' : 'bg-red-300 text-red-900 '
              const message = status === 'ok' ? 'Thank you for getting in touch' : 'Something went wrong, please try again later'
              return (
                <form className='flex flex-col justify-center px-1' onSubmit={handleSubmit}>
                  <div className="flex flex-wrap justify-content">
                    <div className="flex flex-col md:mr-1 mt-2 flex-grow">
                      <input
                        className='border-solid border-2 border-black p-2'
                        type="text"
                        name="firstName"
                        placeholder='first name'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                      />
                      <p className="text-red-700 text-xs italic">
                        {errors.firstName && touched.firstName && errors.firstName}
                      </p>
                    </div>
                    <div className="flex flex-col md:ml-1 mt-2 flex-grow">
                      <input
                        className='border-solid border-2 border-black p-2'
                        type="text"
                        name="lastName"
                        placeholder='last name'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                      />
                      <p className="text-red-700 text-xs italic">
                        {errors.lastName && touched.lastName && errors.lastName}
                      </p>
                    </div>
                  </div>
                  <input
                    className='border-solid border-2 border-black p-2 mt-2'
                    type="email"
                    name="email"
                    placeholder='email'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <p className="text-red-700 text-xs italic">
                    {errors.email && touched.email && errors.email}
                  </p>
                  <textarea
                    className='border-solid border-2 border-black p-2 mt-2'
                    name="message"
                    rows='8'
                    placeholder='Type your message here'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                  />
                  <p className="text-red-700 text-xs italic">
                    {errors.message && touched.message && errors.message}
                  </p>
                  <button className='contactus bg-black text-white  font-title tracking-wide px-4 py-2 mt-2'
                          type="submit"
                          disabled={isSubmitting}>Send message
                  </button>
                  <p
                    className={"mt-2 font-sans font-light px-2 py-2 " + visible + ' ' + color}>{message}</p>
                  <div className="font-sans font-light my-2">Or send us an email at <a
                    href="mailto:info@lookstyler.com">info@lookstyler.com</a></div>
                </form>
              );
            }}
          </Formik>
        )}</Mutation>
    </div>
  </Layout>;
}

export const query = graphql`
    query ContactUs {
        datoCmsContactuspage{
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            textconnect
            textcontact
            image{
                fluid(maxWidth: 1500, imgixParams: {fit: "crop", w:"1000", h:"300", crop:"top"}) {
                    ...GatsbyDatoCmsFluid
                }
            }
        }
    }

`